<!-- 往届学生信息 -- 分班记录 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        has-back
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
        @onReset="onReset"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"class","meta":{"title":"往届分班记录"}}},
  data() {
    return {
      loading: false,
      search:{},
      searchConfig:[
        {
          prop:"is_class",
          tag: 'select',
          placeholder:"请选择班级类型",
          label: 'exam_name',
          value: 'id',
          options: [
            {exam_name: '教学班',id: 1},
            {exam_name: '行政班',id: 2},
          ],
          change: (e,search) => {
            if(e === 1){
              this.searchConfig[1].disabled = false;
            }else {
              search.subject_id = '';
              this.searchConfig[1].disabled = true;
            }
          }
        },
        {
          prop:"subject_id",
          tag: 'select',
          placeholder:"请选择科目",
          disabled: false,
          label: 'subject_name',
          value: 'id',
          options: [],
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig:[
        {label: '序号', prop: 'plain',width: 80},
        {label: '校区', prop: 'school_name'},
        {label: '年级', prop: 'grade_name'},
        {label: '学号', prop: 'student_no'},
        {label: '分班时间', prop: 'date'},
        {label: '科目', prop: 'subject_name'},
        {label: '层级', prop: 'level'},
        {label: '班级', prop: 'class_name'},
        {label: '教师', prop: 'teacher_name'},
        {label: '班级类型', prop: 'is_class_name'},
      ],
    }
  },
  created() {
    this.$store.commit("setPage",1);
    this.getData()
    this.getClass()
  },
  activated() {
    this.getData()
  },
  computed:{
    ...mapState(["page"])
  },
  methods: {
    getData(){
      let params = {
        id: this.$route.query.id,
        page: this.page,
        ...this.search
      }
      this.$_axios2.get("/api/student-rereading/index/divide" , {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    getClass() {
      this.$_axios2.get("/api/student-rereading/index/subject" ).then(res => {
        this.searchConfig[1].options = res.data.data
      })
    },

    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },

    onSearch(val){
      this.loading = true;
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row) {
      this.$router.push('./details?id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage",val);
      //拉取数据
      this.getData()
    },

    onReset(){
      this.searchConfig[1].disabled = false;
    }

  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}
.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}
.table {
  margin-left: 32rem;
}

::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(24, 24, 120);
}
.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}
.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
